<template>
    <div class="wrap">
        <div class="page-main">
            <div class="search">
                <el-form
                    :model="searchParam"
                    status-icon
                    ref="searchData"
                    label-position="left"
                    label-width="90px"
                    :inline="true"
                >
                    <div class="sec">
                        <div class="lable" :class="$i18n.locale=='en'?'lable lable2':'lable'">
                            <span class="sbox"></span>
                            {{ $t('protectFile.fileSearch') }}：
                        </div>
                        <div class="form">
                            <el-form-item label="">
                                <el-input
                                    :disabled="true"
                                    v-model="chooseFile"
                                    autocomplete="off"
                                    :clearable="true"
                                    :placeholder="$t('protectFile.chooseFilePlaceholder')"
                                ></el-input>
                                <input
                                    type="hidden"
                                    v-model="searchParam.fileHash"
                                />
                                <input
                                    type="hidden"
                                    v-model="searchParam.fileHashSha1"
                                />
                            </el-form-item>
                            <el-form-item label="">
                                <input
                                    type="file"
                                    class="addfileinput"
                                    ref="addinputer"
                                    @change="addfile($event)"
                                />
                                <el-button
                                    type="primary"
                                    plain
                                    @click="$refs.addinputer.click()"
                                    >{{ $t('protectFile.chooseFileBtn') }}
                                </el-button>
                                <el-button type="info" plain @click="clearFile"
                                    >{{ $t('protectFile.clearFileBtn') }}
                                </el-button>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="sec">
                        <div :class="$i18n.locale=='en'?'lable lable2':'lable'">
                            <span class="sbox"></span>
                            {{ $t('protectFile.otherSearch') }}：
                        </div>
                        <div class="form">
                            <el-form-item label="">
                                <el-date-picker
                                    class="selectTime"
                                    @change="datetime"
                                    v-model="dataVal"
                                    type="datetimerange"
                                    align="right"
                                    :start-placeholder="$t('protectFile.applyDate')"
                                    :end-placeholder="$t('protectFile.applyDate')"
                                    format="yyyy-MM-dd"
                                    value-format="timestamp"
                                    :default-time="['00:00:00', '23:59:59']"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input
                                    type="text"
                                    v-model="searchParam.nameOrRemark"
                                    autocomplete="off"
                                    :placeholder="$t('protectFile.nameOrRemarkPlaceholder')"
                                    clearable
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="" style="width: 195px">
                                <el-select
                                    clearable
                                    v-model="searchParam.storeType"
                                    :placeholder="$t('protectFile.storeTypePlaceholder')"
                                >
                                    <el-option
                                        :label="$t('protectFile.desensitization')"
                                        value="DESENSITIZE_STORE"
                                    ></el-option>
                                    <el-option
                                        :label="$t('protectFile.saveFile')"
                                        value="SAVE_STORE"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="" style="width: 155px">
                                <el-select
                                    clearable
                                    v-model="searchParam.fileType"
                                    :placeholder="$t('protectFile.fileTypePlaceholder')"
                                >
                                    <el-option
                                        v-for="item in fileType"
                                        :key="item.selectValue"
                                        :label="item.title"
                                        :value="item.selectValue"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label=""
                                style="width: 155px"
                                v-if="childList.length > 1"
                            >
                                <el-select
                                    clearable
                                    filterable
                                    v-model="searchParam.applyAccount"
                                    :placeholder="$t('protectFile.applyAccountPlaceholder')"
                                >
                                    <el-option
                                        v-for="item in childList"
                                        :key="item.accountId"
                                        :label="item.accountName"
                                        :value="item.accountName"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="" :style="$i18n.locale=='en'? 'width:200px' : 'width: 155px'">
                                <el-select
                                    clearable
                                    v-model="searchParam.storeExpiredQueryType"
                                    :placeholder="$t('protectFile.expiredType.lable')"
                                >
                                    <el-option
                                        v-for="item in expiredType"
                                        :key="item.value"
                                        :label="item.title"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="">
                                <el-button type="primary" @click="selectTable">{{ $t('protectFile.searchBtn') }}</el-button>
                                <el-button
                                    type="info"
                                    @click="
                                        ((dataVal = ''),
                                        (searchParam = { page: 1, size: 10 }),
                                        (pageSizeNum = 10)),
                                            selectTable();
                                    "
                                >
                                    {{ $t('protectFile.resetBtn') }}
                                </el-button>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="batchDownload" v-show="selection.length !== 0">
                        <el-checkbox :indeterminate="!incloudToArray" v-model="checked" @change="checkChange"></el-checkbox>
                        <h4>{{$t('protectFile.tableChooseHeader.chooseCount')+selection.length+ $t('protectFile.tableChooseHeader.unit')}}</h4>
                        <div class="operate" @click="downloadZip">
                            <i class="el-icon-ali-zhengshu icon-btn"></i>
                            <p>{{ $t('protectFile.tableChooseHeader.downCertificate') }}</p>
                        </div>
                        <div class="operate" :disabled="disabledDownFile"  @click="downloaXunLei">
                            <i class="el-icon-ali-wendangxiazai icon-btn"></i>
                            <p>{{ $t('protectFile.tableChooseHeader.downOriginal') }}</p>
                        </div>
                        <div class="operate" :disabled="disabledDelFile"  @click="deleteFile">
                            <i class="el-icon-delete icon-btn"></i>
                            <p>{{ $t('protectFile.tableChooseHeader.deleteOriginal') }}</p>
                        </div>
                        <div class="operate" @click="batchRemarks">
                            <i class="el-icon-ali-beizhu icon-btn"></i>
                            <p>{{ $t('protectFile.tableChooseHeader.batchRemarks') }}</p>
                        </div>
                        <div class="operate" @click="generateBills">
                            <i class="el-icon-ali-zhangdanchaxunbill icon-btn"></i>
                            <p>{{ $t('protectFile.tableChooseHeader.generateBills') }}</p>
                        </div>
                    </div>
                    <ExTable
                        :table-list="tableData"
                        :header="header"
                        :isIndex="false"
                        height="300"
                        ref="multipleTable"
                        :select="true"
                        :total="total"
                        :tableId="1"
                        tableName="table"
                        :activeIndex="parseInt(searchParam.page)"
                        :pageSizeNum="pageSizeNum"
                        :showHeader="selection.length === 0"
                        @clickPage="clickPage"
                        @clickSize="clickSize"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            slot="storeType"
                            :label="$t('protectFile.tableHeader.lable5')"
                            align="left"
                            min-width="120"
                            class="storeType"
                            :render-header="renderHeader"
                        >
                            <template slot-scope="scope">
                                <div class="storeSize">
                                    <span>{{scope.row.storeType==0? $t('protectFile.desensitization') : $t('protectFile.saveFile')}}</span>
                                    <span class="fileSize" v-if="scope.row.storeType!==0 && scope.row.applyFileSize">（{{ scope.row.applyFileSize | fileSize}}）</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            slot="storeExpiredAt"
                            :label="$t('protectFile.tableHeader.lable8')"
                            align="left"
                            width="170"
                            class="storeExpiredAt"
                            :render-header="renderHeader"
                        >
                            <template slot-scope="scope">
                                <div class="expiredAtWrap">
                                    <span v-if="scope.row.storeExpiredAt && scope.row.storeType==1">{{scope.row.storeExpiredAt | formatYMD}}</span>
                                    <span v-else>-</span>
                                    <div v-if="scope.row.storeType==1" class="expiredType">
                                        <div class="p" v-if="scope.row.expiredTagType==2">
                                            <i class="el-icon-warning red"></i>
                                            <span class="red">{{$t('protectFile.expiredTypeText.title2')}}</span>
                                        </div>
                                        <div class="p" v-if="scope.row.expiredTagType==1">
                                            <i class="el-icon-warning orange"></i>
                                            <span class="orange">{{$t('protectFile.expiredTypeText.title1')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>


                        
                        <el-table-column
                            slot="applyRemark"
                            :label="$t('protectFile.tableHeader.lable6')"
                            align="left"
                            min-width="130"
                            class="applyRemark"
                            :render-header="renderHeader"
                        >
                            <template slot-scope="scope">
                                <div
                                    v-if="!scope.row.modified"
                                    class="applyRemark"
                                >
                                    <el-tooltip
                                        effect="dark"
                                        :content="scope.row.applyRemark"
                                        placement="bottom"
                                    >
                                        <p
                                            @click="
                                                showApplyRemark(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            class="Pedit"
                                        >
                                            {{ scope.row.applyRemark }}
                                        </p>
                                    </el-tooltip>

                                    <span
                                        class="edit"
                                        @click="
                                            showApplyRemark(
                                                scope.$index,
                                                scope.row
                                            )
                                        "
                                    >
                                        <i class="el-icon-edit-outline"></i>
                                    </span>
                                </div>
                                <el-tooltip
                                    effect="dark"
                                    :content="scope.row.applyRemark"
                                    v-else
                                    :disabled="
                                        scope.row.applyRemark &&
                                        scope.row.applyRemark.length > 0
                                            ? false
                                            : true
                                    "
                                    placement="bottom"
                                >
                                    <div class="applyRemark">
                                        <el-input
                                            v-model="scope.row.applyRemark"
                                            @keyup.enter.native="
                                                editApplyRemark(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            maxlength="100"
                                        />
                                        <el-button
                                            class="editBtn"
                                            type="primary"
                                            icon="el-icon-check"
                                            @click="
                                                editApplyRemark(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                        ></el-button>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            slot="operate"
                            :label="$t('protectFile.tableHeader.lable7')"
                            width="180"
                        >
                            <template slot-scope="scope">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('protectFile.tableOperate.btn1')"
                                    placement="top"
                                >
                                    <span @click="lookDetail(scope.row)" >
                                      <svg-icon
                                            data_iconName="xiangqing"
                                            className="obtn"
                                            style="margin-left:0"
                                      />
                                    </span>
                                </el-tooltip>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="
                                        scope.row.sharingStatus === 0
                                            ? $t('protectFile.tableOperate.btn2')
                                            : scope.row.sharingStatus === 1
                                            ? $t('protectFile.tableOperate.btn2')
                                            : ''
                                    "
                                    placement="top"
                                >
                                    <span @click="shareDetail(scope.row)"
                                        ><svg-icon
                                            data_iconName="share"
                                            className="obtn share"
                                    /></span>
                                </el-tooltip>

                                <el-dropdown @command="downCommand($event,scope.row)">
                                  <span>
                                      <svg-icon
                                            data_iconName="download"
                                            className="obtn"
                                      />
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item command="Certificate">{{ $t('protectFile.tableOperate.btn3') }}</el-dropdown-item>
                                      <el-dropdown-item command="Original" v-if="scope.row.storeType!==0">{{$t('protectFile.tableOperate.btn4')}}</el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                                
                            </template>
                        </el-table-column>
                    </ExTable>
                </el-form>
            </div>
        </div>
        <div class="zhe" v-if="zhe">
            <div class="progress">
                <el-progress
                    type="circle"
                    :percentage="percentage"
                ></el-progress>
            </div>
        </div>
        <ShareInfo ref="shareInfo" @shareMethods="shareMethods" />
        <ShareAddress ref="shareAddress" @shareMethods="shareMethods" />
        <ShareModal ref="shareModal" :searchParam="queryParam" />

        <el-dialog :title="$t('protectFile.remarksDiaBox.title')" :visible.sync="remarksDiaBox" width="650px" class="myDialogNormal remarksDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <h4>{{ $t('protectFile.remarksDiaBox.des') }}</h4>
                <el-input
                    type="textarea"
                    :placeholder="$t('protectFile.remarksDiaBox.placeholder')"
                    v-model="batchRemarksText"
                    show-word-limit
                    class="notesTextarea"
                    maxlength="100"
                >
                </el-input>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="remarksDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="batchRemarksUpdate">{{ $t('diaBtn.confirm') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="$t('protectFile.billDiaBox.title')" :visible.sync="billDiaBox" width="450px" class="myDialogNormal billDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <h3>{{ $t('protectFile.billDiaBox.choose') }}<b>{{selection.length}}</b>{{ $t('protectFile.billDiaBox.des') }}</h3>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="billDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="getBillList">{{ $t('diaBtn.confirm') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="$t('protectFile.emialDiaBox.title')" :visible.sync="emialDiaBox" width="550px" class="myDialogNormal emialDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <el-form ref="emaliForm" label-width="130px" label-position="left" :model="ruleEmail" :rules="rules">
                    <el-form-item prop="email">
                        <span slot="label">
                            <b style="color:#F56C6C;margin-right: 4px;">*</b>
                            {{ $t('protectFile.emialDiaBox.email')}}
                        </span>
                        <el-input 
                            v-model="ruleEmail.email" 
                            class="myinput" 
                            :placeholder="$t('protectFile.emialDiaBox.placeholder')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label-width="130px">
                        <span slot="label">
                            <b style="color:#F56C6C;margin-right: 4px;">*</b>
                            {{ $t('protectFile.emialDiaBox.slide') }}
                        </span>
                        <div style="padding-top:3px">
                            <Verification @verification="verification" ref="Verification" />
                        </div> 
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="emialDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="sendBill('emaliForm')">{{ $t('diaBtn.confirm') }}</el-button>
            </div>
        </el-dialog>

        <!-- 已过期且余额不足情况下，下载 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="downNoEnoughDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div :class="$i18n.locale=='en'? 'dialog_cnt dialog_cnt_en':'dialog_cnt'">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3 v-if="userInfo.userType == 'ADMIN_ACCOUNT'">{{ $t('protectFile.downNoEnoughDiaBox.desParents') }}</h3>
                <h3 class="alignLeft" v-else>{{$t('protectFile.downNoEnoughDiaBox.desChild')}}</h3>
                <p>
                    {{$t('protectFile.downNoEnoughDiaBox.node1')}}
                    <b class="red">{{expiredDays}}</b>
                    {{$t('protectFile.downNoEnoughDiaBox.node2')}}
                    <b class="blue">{{storeTsCount}}</b>
                    {{$t('protectFile.downNoEnoughDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="downNoEnoughDiaBox=false" v-if="userInfo.userType == 'ADMIN_ACCOUNT'">
                    {{ $t('diaBtn.cancel') }}
                </el-button>
                <el-button class="copybtn" type="primary" @click="goRecharge" v-if="userInfo.userType == 'ADMIN_ACCOUNT'">
                    {{ $t('diaBtn.recharge') }}
                </el-button>
                <el-button class="copybtn" type="primary" @click="downNoEnoughDiaBox=false" v-else>
                    {{ $t('diaBtn.know') }}
                </el-button>
            </div>
        </el-dialog>
        <!-- 身份子账号，文件已过期且主账号分配余额不足情况下，下载 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="downNoEnoughChildDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3 class="alignLeft">{{$t('protectFile.downNoEnoughChildDiaBox.desParents')}}</h3>
                <p>
                    {{$t('protectFile.downNoEnoughChildDiaBox.node1')}}
                    <b class="red">{{expiredDays}}</b>
                    {{$t('protectFile.downNoEnoughChildDiaBox.node2')}}
                    <b class="blue">{{storeTsCount}}</b>
                    {{$t('protectFile.downNoEnoughChildDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button class="copybtn" type="primary" @click="downNoEnoughChildDiaBox=false">{{ $t('diaBtn.know') }}</el-button>
            </div>
        </el-dialog>
        <!-- 已过期且余额足够情况下，下载 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="downEnoughDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3>{{$t('protectFile.downEnoughDiaBox.desParents')}}</h3>
                <p>
                    {{$t('protectFile.downEnoughDiaBox.node1')}}
                    <b class="red">{{expiredDays}}</b>
                    {{$t('protectFile.downEnoughDiaBox.node2')}}
                    <b class="blue">{{storeTsCount}}</b>
                    {{$t('protectFile.downEnoughDiaBox.node3')}}
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="downEnoughDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="handlDownload">{{ $t('diaBtn.download') }}</el-button>
            </div>
        </el-dialog>

        <!-- 充值后回来的弹框 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="updateBalanceDiaBox" width="450px" class="myDialogNormal downDiaBox rechargeDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <h3>{{$t('protectFile.updateBalanceDiaBox.des')}}</h3>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="goRecharge">{{ $t('diaBtn.recharge') }}</el-button>
                <el-button class="copybtn" type="primary" @click="download">{{ $t('diaBtn.download') }}</el-button>
            </div>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog :title="$t('protectFile.downNoEnoughDiaBox.title')" :visible.sync="deleteDiaBox" width="470px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div :class="$i18n.locale=='en'? 'dialog_cnt dialog_cnt_en':'dialog_cnt'">
                <div class="icon"><i class="el-icon-warning"></i></div>
                <h3 class="alignLeft">{{$t('protectFile.deleteDiaBox.des')}}</h3>
                <p v-if="hasTM">{{$t('protectFile.deleteDiaBox.tip')}}</p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button type="info" plain @click="deleteDiaBox=false">{{ $t('diaBtn.cancel') }}</el-button>
                <el-button class="copybtn" type="primary" @click="handleDelete">{{ $t('diaBtn.confirm') }}</el-button>
            </div>
        </el-dialog>

        <div class="mask" v-show="billMask">
            <div class="bill">
                <b class="close" @click="billMask=false"><i class="el-icon-close"></i></b>
                <div class="bill_header">
                    <div class="left">
                        <img width="293" height="44" src="@/assets/images/bill/logo.png" alt="" />
                        <img class="slogan" width="181" height="35" src="@/assets/images/bill/slogan.png" alt="" />
                    </div>
                    <div class="right">
                        <dl>
                            <dt><img src="@/assets/images/bill/barcode_Wechat.png" alt=""></dt>
                            <dd>
                                <p>可信时间戳</p>
                                <p>微信公众号</p>
                            </dd>
                        </dl>
                        <dl>
                            <dt><img src="@/assets/images/bill/barcode_App.png" alt=""></dt>
                            <dd>
                                <p>权利卫士</p>
                                <p>下载</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div class="bill_body">
                    <div class="bill_title">联合信任可信时间戳电子账单</div>
                    <div class="bill_chapter">
                        <img src="@/assets/images/bill/stamp.png" alt="">
                    </div>
                    <div class="bill_list">
                        <div class="total">
                            <span><b>账单生成日期：</b>{{createAt | formatYMD}}</span>
                            <span><b>文件数：</b>{{billData.length}} 条</span>
                            <span><b>合计金额：</b>¥{{amount}}</span>
                        </div>
                        <div class="bill_table">
                            <ExTable
                                :table-list="billData"
                                :header="billHeader"
                                :isIndex="true"
                                :tableId="2"
                                :stripe="true"
                                :border="true"
                                tableName="billTable"
                                :hasPagination="false"
                                :headerStyle="headerStyle"
                                :maxHeight="300"
                            />
                        </div>
                        <div class="bill_des">本账单经过可信时间戳®电子签名，具有与纸质账单同等法律效力，可登录 https://v.tsa.cn 验证</div>
                    </div>
                </div>
                <div class="bill_footer">
                    <el-button type="warning" @click="sendEmaiBill">发送账单</el-button>
                    <el-button type="primary" @click="downBill">下载账单</el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import qs from 'qs'
import ExTable from "@/components/ExTable";
import Verification from "@/components/VaVerification";
import { user,opusList, findChild, updateOpus, multiUpdate, getBill, billDown,findBalance,payArrearageMoney,findOpusInfo,deleteFileMulti,getPermission} from "@/api/service";
import { formatDate,formatYMD, filterParams,isInclude,fileSize} from "@/utils/filter";
import CryptoJS from "crypto-js";
import { arrayBufferToWordArray } from "@/assets/js/buffer";
import ShareInfo from "@com/share/shareInfo";
import ShareAddress from "@com/share/shareAddress";
import ShareModal from "@com/share/shareModal";
import { getShareInfo } from "@/api/share";
import { mapState } from "vuex";
import {saveAs} from 'file-saver'

var pos = 0;
var end;
var chunkSize = 204800; //409600;//204800;
var lastprogress = 0;
var sha256 = CryptoJS.algo.SHA256.create();
var sha1 = CryptoJS.algo.SHA1.create();
export default {
    data() {
        var email=(rule, value, callback) => {
            var reg = new RegExp(
                "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+\\.){1,63}[a-z0-9]+$"
                );
            if (!reg.test(value)) {
                callback(new Error(this.$t('protectFile.emailVer')));
            }
            callback();
        };
        return {
            chooseFile: "",
            dataVal: "",
            checked:false,
            selection:[],
            pageSizeNum: 10,
            searchParam: {
                page: 1,
                size: 10,
                fileHash: "",
                fileHashSha1: "",
                fileType: "",
                sourceFileUploadType: "",
                opusName: "",
                startAt: "",
                endAt: "",
                applyAccount: "",
                storeExpiredQueryType:"",
            },
            queryParam:{},//给分享弹框加参数，带到详情页
            
            tableData: [],
            total: 10,
            
            fil: {},
            sha256: "",
            zhe: false,
            percentage: 0,
            childList: [],
            // 弹框
            remarksDiaBox:false,
            billDiaBox:false,
            billMask:false,
            emialDiaBox:false,
            downEnoughDiaBox:false,
            downNoEnoughDiaBox:false,
            downNoEnoughChildDiaBox:false,
            updateBalanceDiaBox:false,
            deleteDiaBox:false,

            batchRemarksText:'',//批量备注
            billData:[],//账单
            billHeader:[
                {
                    width: 215,
                    prop: "serialNo",
                    label: "证书编号",
                },
                {
                    minWidth: 120,
                    prop: "name",
                    label: "文件名称",
                },
                {
                    minWidth: 100,
                    prop: "tsaAt",
                    label: "签发时间",
                    formatter: (row) => {
                        return formatDate(row.tsaAt);
                    },
                },
                {
                    minWidth: 80,
                    prop: "amount",
                    label: "金额（元）",
                },
            ],
            ruleEmail:{
                email:'',
            },
            rules:{
                email: [
                    { required: true, message: this.emailMessage, trigger: "blur" },
                    { validator: email, trigger: "blur" }
                ],
            },
            verify: false,
            nvc:'',
            amount:'',
            createAt:'',
            expiredDays:'',
            storeTsCount:'',
            currentRow:{},
            disabledDownFile:false,
            disabledDelFile:false,
            hasTM:false,
        };
    },

    filters: {
        formatYMD: formatYMD,
        fileSize:fileSize,
    },

    components: {
        ExTable,
        ShareInfo,
        ShareAddress,
        ShareModal,
        Verification,
    },

    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.userInfo,
            meInfo: (state) => state.userInfo.me,
        }),
        emailMessage(){
            return this.$t('protectFile.emailEnter')
        },
        access_token(){
            return this.$store.state.userInfo.me.accessToken
        },
        incloudToArray(){
            let selectionID = this.selection.map((item)=>{return item.id})
            let tableDataID = this.tableData.map((item)=>{return item.id})
            return isInclude(selectionID,tableDataID)
        },
        fileType(){
            return  [
                {
                    title: this.$t('applyTime.fileType.title1'),
                    value: 109,
                    selectValue: "BUSINESS_SECURITY",
                },
                {
                    title: this.$t('applyTime.fileType.title2'),
                    value: 119,
                    selectValue: "INDUSTRIAL_PROJECT",
                },
                {
                    title: this.$t('applyTime.fileType.title3'),
                    value: 108,
                    selectValue: "SOFTWARE",
                },
                {
                    title: this.$t('applyTime.fileType.title4'),
                    value: 120,
                    selectValue: "HARD_PROJECT",
                },
                {
                    title: this.$t('applyTime.fileType.title5'),
                    value: 121,
                    selectValue: "SCIENTIFIC_PAYOFFS",
                },
                {
                    title: this.$t('applyTime.fileType.title6'),
                    value: 122,
                    selectValue: "DATA_ASSETS",
                },
                {
                    title: this.$t('applyTime.fileType.title7'),
                    value: 123,
                    selectValue: "TRADEMARK_DESIGN",
                },
                {
                    title: this.$t('applyTime.fileType.title8'),
                    value: 124,
                    selectValue: "PATENT_DOCUMENT",
                },
                {
                    title: this.$t('applyTime.fileType.title9'),
                    value: 101,
                    selectValue: "WORD",
                },
                {
                    title: this.$t('applyTime.fileType.title10'),
                    value: 112,
                    selectValue: "VIDEO",
                },
                {
                    title: this.$t('applyTime.fileType.title11'),
                    value: 110,
                    selectValue: "PHOTOGRAPHY",
                },
                {
                    title:this.$t('applyTime.fileType.title12'),
                    value: 104,
                    selectValue: "FINE_ARTS",
                },
                {
                    title: this.$t('applyTime.fileType.title13'),
                    value: 102,
                    selectValue: "MUSIC",
                },
                {
                    title: this.$t('applyTime.fileType.title14'),
                    value: 105,
                    selectValue: "DESIGN",
                },
                {
                    title: this.$t('applyTime.fileType.title15'),
                    value: 125,
                    selectValue: "HANDOUTS_COURSEWARE",
                },
                {
                    title: this.$t('applyTime.fileType.title17'),
                    value: 131,
                    selectValue: "AIGC",
                },
                {
                    title: this.$t('applyTime.fileType.title16'),
                    value: 999,
                    selectValue: "OTHER",
                },
            ]
        },
        expiredType(){
            return [
                {
                    title: this.$t('protectFile.expiredType.title1'),
                    value:0,
                },
                {
                    title: this.$t('protectFile.expiredType.title2'),
                    value:1,
                },
                {
                    title: this.$t('protectFile.expiredType.title3'),
                    value:2,
                },
            ]
        },
        header(){
            return [
                {
                    minWidth: 150,
                    prop: "opusName",
                    label: this.$t('protectFile.tableHeader.lable1'),
                    renderHeader:this.renderHeader,
                },
                {
                    prop: "operatedAt",
                    minWidth: 120,
                    label: this.$t('protectFile.tableHeader.lable2'),
                    align:'center',
                    formatter: (row) => {
                        return formatDate(row.operatedAt);
                    },
                    renderHeader:this.renderHeader,
                },
                {
                    prop: "applyAccount",
                    minWidth: 120,
                    label: this.$t('protectFile.tableHeader.lable3'),
                    renderHeader:this.renderHeader,
                },
                {
                    minWidth: 80,
                    prop: "fileTypeName",
                    label: this.$t('protectFile.tableHeader.lable4'),
                    formatter: (row) => {
                        if(this.$i18n.locale==='en'){
                            return row.fileEnTypeName
                        }else{
                            return row.fileTypeName
                        }
                    },
                    renderHeader:this.renderHeader,
                },
                // {
                //     width: 120,
                //     prop: "storeType",
                //     label: "认证方式",
                //     formatter: (row) => {
                //         if (row.storeType == 0) {
                //             return "脱敏认证";
                //         } else {
                //             return "存证";
                //         }
                //     },
                // },
                {
                    minWidth: 120,
                    slot: "storeType",
                },
                {
                    minWidth: 120,
                    slot: "storeExpiredAt",
                },
                {
                    minWidth: 120,
                    slot: "applyRemark",
                },
                {
                    width: "200",
                    slot: "operate",
                },
            ]
        } 
    },

    created() {
        this.init();
    },

    mounted() {},

    methods: {
        init(){
            let param = this.$route.query;
            this.pageSizeNum=param.size?param.size*1:10;
            if (JSON.stringify(param) !== "{}") {
                this.chooseFile = param.chooseFile;
                if (param.startAt) {
                    this.dataVal = [param.startAt, param.endAt];
                }
                delete param.chooseFile;
                // if (param.fileType) {
                //   param.fileType = parseInt(param.fileType)
                // }
                if (param.sourceFileUploadType) {
                    param.sourceFileUploadType = parseInt(
                        param.sourceFileUploadType
                    );
                }
                param.storeExpiredQueryType=param.storeExpiredQueryType?parseInt(param.storeExpiredQueryType):'';
                this.searchParam = {...this.searchParam,...param};
            }
            this.getOpusList(filterParams(this.searchParam));
            if (this.meInfo.parentId ==='0') {
                this.getChildList()
            }
        },
        async getChildList() {
            let res = await findChild();
            if (res) {
                this.childList = res;
            }
        },
        async getOpusList(data) {
            let obj = data;
            if (data.opusName) {
                obj.opusName = encodeURIComponent(data.opusName);
            }
            let res = await opusList(obj);
            if(res.pages!==0 && this.searchParam.page>res.pages){
                this.searchParam.page = 1;
                this.getOpusList(filterParams(this.searchParam));
                return;
            }
            this.tableData = res.data;
            this.tableData.forEach((item) => {
                item.modified = false;
            });
            this.total = res.total;
            //每次更新表格数据，设置当前复选框状态
            let selectionID = this.selection.map((item)=>{return item.id})
            let tableDataID = this.tableData.map((item)=>{return item.id})
            if (isInclude(selectionID,tableDataID)) {
                this.checked = true
            } else {
                this.checked = false
            }
        },
        datetime(e) {
            if (e) {
                this.searchParam.startAt = e[0];
                this.searchParam.endAt = e[1];
            } else {
                this.searchParam.startAt = "";
                this.searchParam.endAt = "";
            }
        },
        selectTable() {
            this.selection=[];
            this.$refs.multipleTable.$refs.table.clearSelection();
            this.searchParam.page = 1;
            this.getOpusList(filterParams(this.searchParam));
        },
        clickPage(page) {
            this.searchParam.page = page;
            this.getOpusList(filterParams(this.searchParam));
        },
        clickSize(size) {
            this.total = 10;
            this.searchParam.page = 1;
            this.searchParam.size = size;
            this.pageSizeNum = size;
            this.getOpusList(filterParams(this.searchParam));
        },
        handleSelectionChange(val){
            this.selection=val;
            let selectionID = this.selection.map((item)=>{return item.id})
            let tableDataID = this.tableData.map((item)=>{return item.id})
            if (isInclude(selectionID,tableDataID)) {
                this.checked = true
            } else {
                this.checked = false
            }
            let noDownloadFile=[];//不能下载的文件
            let noDelFile=[];//不能删除的文件
            this.selection.forEach(item => {
                if(item.storeType==0){//脱敏
                    noDelFile.push(item.id)
                }
                if(item.storeType==0 || item.isExpired){ //脱敏或存证已过期
                    noDownloadFile.push(item.id)
                }
            });

            noDelFile.length==selectionID.length?this.disabledDelFile=true : this.disabledDelFile=false;
            noDelFile.length>0?this.hasTM=true:this.hasTM=false;
            if(noDownloadFile.length==selectionID.length){
                this.disabledDownFile=true;
            }else{
                this.disabledDownFile=false;
            }

        },
        checkChange(val){
            if (val) {
                //合并分页的选中项，为防止在一个页面反复全选需要去重
                let obj = []
                this.selection=[...this.selection,...this.tableData];
                this.selection =this.selection.reduce(function (prev,cur) {
                                    obj[cur.id] ? '' : (obj[cur.id] = true && prev.push(cur))
                                    return prev
                                },[])
                this.$refs.multipleTable.$refs.table.toggleAllSelection()
            } else {
                //先取消该页的勾选
                this.$refs.multipleTable.$refs.table.toggleAllSelection()
                //在将该页数据从总选中数组中去除
                this.selection = this.selection.filter((item) => !(this.tableData.some((i) => i == item)))
                console.log(this.selection)
                // if(this.selection.length==0){
                //     console.log(val)
                //     this.$refs.multipleTable.$refs.table.clearSelection();
                // }
            }
        },
        // 下载证书
        async downTSA(serialNo) {
            let baseURL
            if (process.env.NODE_ENV === 'development') {
                baseURL = '/api/api'
            } else {
                baseURL = '/opus/api'
            }
            //window.open苹果浏览器下载中文文件名不乱码
            window.open(baseURL + `/opus/download/file/${serialNo}`)
            // let link = document.createElement('a')
            // link.style.display = 'none'
            // link.target = '_blank'
            // link.href = baseURL + `/opus/download/file/${serialNo}`
            // document.body.appendChild(link)
            // link.click()
            // document.body.removeChild(link)
        },
        downPDF(){
            window.open(this.currentRow.pdfUrl)
        },
        //下载原件
        downFile(row){
            let link = document.createElement("a");
            link.style.display = "none";
            link.target = "_blank";
            link.href = row.sourceFileStorePathUrl
            link.download = row.opusName
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async downCommand(e,row){
            if(e=='Certificate'){
                this.downTSA(row.serialNo)
            }else{
                console.log("下载原件");
                this.currentRow=row;
                let res = await findOpusInfo(this.currentRow.serialNo);
                if(res.isExpired){//已过期
                    let {deductible} = await findBalance(res.storeTsCount);
                    this.expiredDays=res.expiredDays;
                    this.storeTsCount=res.storeTsCount;
                    if(deductible==1){ //余额够扣给出提示继续下载
                        this.downEnoughDiaBox=true; 
                    }else if(deductible == 4){ //身份主账号，余额不足
                        this.downNoEnoughDiaBox=true;
                    }else if(deductible==2){//身份子账号，主账号余额不足
                        this.downNoEnoughDiaBox=true;
                    }else{//3 身份子账号，主账号分配次数达上限
                        this.downNoEnoughChildDiaBox=true;
                    }
                }else{//下载
                    this.downFile(row)
                }
            }
        },
        async download() {
            let res = await findOpusInfo(this.currentRow.serialNo);
            this.updateBalanceDiaBox=false;
            if(res.isExpired){//已过期
                let {deductible} = await findBalance(res.storeTsCount);
                if(deductible==1){ //在充值成功提示框点击继续下载满足下载不需要弹框提示了，直接下载
                    // this.downEnoughDiaBox=true;
                    await this.handlDownload();
                }else if(deductible == 4){ //身份主账号，余额不足
                    this.downNoEnoughDiaBox=true;
                }else if(deductible==2){//身份子账号，主账号余额不足
                    this.downNoEnoughDiaBox=true;
                }else{//3 身份子账号，主账号分配次数达上限
                    this.downNoEnoughChildDiaBox=true;
                }
            }else{//下载
                this.downFile(res)
            }
        },
        async handlDownload(){
            try {
                // 先调扣费接口
                await payArrearageMoney({
                    serialNo:this.currentRow.serialNo,
                    partnerType:this.$getlocalStorage('queryData')?.partnerType==1? 1:0
                })
                let res = await findOpusInfo(this.currentRow.serialNo);
                this.downFile(res);
                this.downEnoughDiaBox=false;
                this.updateBalance();
                this.getOpusList(filterParams(this.searchParam));//余额有变动，更新列表数据
            } catch (error) {
                this.downEnoughDiaBox=false;
                if(error.data.code == 4){
                    this.downNoEnoughDiaBox=true;
                }else if(error.data.code == 2){
                    this.downNoEnoughDiaBox=true;
                }else{
                    this.downNoEnoughChildDiaBox=true;
                }
                return false
                // this.$message.error(error.message)
            }
        },
        addfile(e) {
            let inputDOM = this.$refs.addinputer;
            this.fil = inputDOM.files;
            let size = Math.floor(this.fil[0].size);
            this.chooseFile = inputDOM.files[0].name;
            this.zhe = true;
            pos = 0;
            end;
            chunkSize = 204800; //409600;//204800;
            lastprogress = 0;
            sha256 = CryptoJS.algo.SHA256.create();
            sha1 = CryptoJS.algo.SHA1.create();
            this.progressiveRead(inputDOM.files[0]);
        },
        progressiveReadNext(file, reader) {
            var that = this;
            end = Math.min(pos + chunkSize, file.size);
            if (file.slice) {
                var blob = file.slice(pos, end);
            } else if (file.webkitSlice) {
                var blob = file.webkitSlice(pos, end);
            }
            reader.readAsArrayBuffer(blob);
            reader.onload = function (e) {
                pos = end;
                var wordArray = arrayBufferToWordArray(e.target.result);
                sha256.update(wordArray);
                sha1.update(wordArray);
                var progress = Math.floor((pos / file.size) * 100);
                if (progress > lastprogress) {
                    //console.log(progress)
                    that.percentage = progress;
                    lastprogress = progress;
                }
                if (pos < file.size) {
                    setTimeout(that.progressiveReadNext(file, reader), 0);
                } else {
                    that.searchParam.fileHashSha1 = sha1.finalize().toString().toUpperCase();
                    that.searchParam.fileHash = sha256.finalize().toString().toUpperCase();
                    // console.log(that.sha1)
                    // console.log(that.sha256)
                    setTimeout(function () {
                        that.zhe = false;
                        that.percentage = 0;
                        that.selectTable();
                    }, 1000);
                }
            };
        },
        progressiveRead(file) {
            // 20KiB at a time
            pos = 0;
            chunkSize = 204800; //409600;//204800;
            lastprogress = 0;
            let reader = new FileReader();
            setTimeout(this.progressiveReadNext(file, reader), 0);
        },
        lookDetail(row) {
            this.searchParam.serialNo = row.serialNo;
            if (this.chooseFile) {
                this.searchParam.chooseFile = this.chooseFile;
            }
            this.$router.push({
                path: "/time/FileDetail",
                query: filterParams(this.searchParam),
            });
        },
        clearFile() {
            this.chooseFile = "";
            this.searchParam.fileHash = "";
            this.searchParam.fileHashSha1 = "";
            this.fil = {};
            this.$refs.addinputer.value = null;
            this.selectTable();
        },
        // 批量备注
        batchRemarks(){
            this.batchRemarksText='';
            this.remarksDiaBox=true;
        },
        batchRemarksUpdate(){
            let paramsData=[];
            this.selection.map((item)=>{
                paramsData.push({
                    applyRemark:this.batchRemarksText,
                    serialNo:item.serialNo
                })
            })
            multiUpdate(paramsData).then((res)=>{
                this.remarksDiaBox=false;
                this.$message.success(this.$t('protectFile.updateSuccess'));
                this.selectTable();
            }).catch((err)=>{
                this.$message.error(this.$t('protectFile.updateError'));
            })
        },
        // 生成账单
        generateBills(){
            this.billData=this.selection;
            this.billDiaBox=true;
        },
        getBillList(){
            let serialNos = this.selection.map((item)=>{return item.serialNo})
            getBill({serialNos: serialNos}).then((res)=>{
                this.billDiaBox=false;
                this.billMask=true;
                this.billData=res.datas;
                this.createAt=res.createAt;
                this.amount=res.amount;
            }).catch((err)=>{
                this.$message.error(err.data.message)
            })
            
        },
        downBill(){
            let serialNos = this.selection.map((item)=>{return item.serialNo})
            billDown({serialNos: serialNos}).then((res)=>{
                this.emialDiaBox=false;
                this.billMask=false;
                this.verify=false;
                let link = document.createElement('a')
                link.style.display = 'none'
                link.target = '_blank'
                link.href = res.billPath
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }).catch((err)=>{
                this.verify=false;
                this.$message.error(this.$t('protectFile.downloadError'))
            })
        },
        sendBill(form){
            let serialNos = this.selection.map((item)=>{return item.serialNo})
            this.$refs[form].validate(async (valid) => {
                if (valid) {
                    if (!this.verify) {
                        this.$message.error(this.$t('protectFile.varSlide'));
                        return false;
                    }
                    let obj={
                        serialNos: serialNos,
                        email:this.ruleEmail.email,
                        nvc:this.nvc
                    }
                    billDown(obj).then((res)=>{
                        this.emialDiaBox=false;
                        this.verify=false;
                        this.billMask=false;
                        this.$refs.Verification.resetNvc();
                        this.$message.success(this.$t('protectFile.sendEmailSuccess'))
                    }).catch((err)=>{
                        this.verify=false;
                        this.$refs.Verification.resetNvc();
                        this.$message.error(this.$t('protectFile.sendEmailError'))
                    })
                }
            })
        },
        sendEmaiBill(){
            this.emialDiaBox=true
            if(this.verify){
                this.verify=false;
                this.$refs.Verification.resetNvc();
            }
        },
        // 批量下载证书
        downloadZip(){
            if(this.selection.length>170){
                this.$message.error(this.$t('protectFile.maximum'))
                return
            }
            let serialNos = this.selection.map((item)=>{return item.serialNo})
            let obj = {serialNos: serialNos}
            var baseURL;
            if (process.env.NODE_ENV === 'development') {
                baseURL = '/api/api'
            } else {
                baseURL = '/opus/api'
            }
            window.open(baseURL +`/opus/downloadMultiFile?${qs.stringify(obj, { indices: false })}`)
        },
        // 批量下载原件生成txt下载链接去迅雷下载
        downloaXunLei(){
            for (var i = 0; i < this.selection.length; i++) {
                if(this.selection[i].storeType==0 || this.selection[i].isExpired){
                    this.$message.warning(this.$t('protectFile.downloadTips'))
                    break;
                }
            }
            let downLinks = this.selection.map((item)=>{return item.sourceFileStorePathUrl})
            let linkStr='';
            downLinks.forEach((v)=>{
                if(v){
                    linkStr=linkStr+v+'\r\n\r\n'
                }
            })
            let content = `${this.$t('protectFile.downTXT')}\r\n---------------------------------------------------------------------------------------------------------------\r\n${linkStr}\r\n`
            let blob = new Blob([content], {
                type: 'text/plain;charset=utf-8',
            })
            saveAs(blob, `${this.$t('protectFile.txtFileName')}.txt`)
        },
        //批量删除存证的原件
        async deleteFile(){
            try {
                if (this.meInfo.parentId !=='0') {//1.判断当前登录账号是子账号 &&子账号配置了不能删除原件的权限
                    let {permissionValue}=await getPermission({type:1});
                    if(permissionValue===1){//不可以删除
                        this.$message.error(this.$t('protectFile.deleteChildMsg'))
                        return
                    }
                }
                this.deleteDiaBox=true;// 弹框提示
            }catch (error) {
                console.log(error)
            }
        },
        async handleDelete(){
            try {
                // 删除接口
                let serialNos = this.selection.map((item)=>{return item.serialNo})
                let obj = {serialNos: serialNos}
                await deleteFileMulti(obj);
                this.deleteDiaBox=false;
                this.$message.success(this.$t('protectFile.deleteSuccess'));
                this.disabledDelFile=true;
                this.disabledDownFile=true;
                this.getOpusList(filterParams(this.searchParam));
            } catch (error) {
                console.log(error)
            }
        },
        async verification(data) {
            if (data) {
                this.verify=data.verify;
                this.nvc=data.nvc;
                let reg = new RegExp(
                    "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+\\.){1,63}[a-z0-9]+$"
                );
                if (!reg.test(this.ruleEmail.email)) {
                    this.$refs.emaliForm.validateField("email");
                    this.verify=false;
                    this.$refs.Verification.resetNvc();
                    return false;
                }
            }
        },
        // 分享
        async shareDetail(row) {
            if (row.sourceFileUploadType === 0) {
                this.searchParam.serialNo = row.serialNo;
                if (this.chooseFile) {
                    this.searchParam.chooseFile = this.chooseFile;
                }
                this.queryParam= filterParams(this.searchParam),
                this.$refs.shareModal.handleShow(
                    row,
                    this.$t('shareModal.shareToast1'),
                    0
                );
                return;
            }
            let res=await user();
            this.$store.commit('userInfo/setUserInfo', res);
            if (res.identificationStatus === 2) {
                this.$refs.shareModal.handleShow(
                    row,
                    this.$t('shareModal.shareToast2'),
                    1
                );
                return;
            }
            // if (this.meInfo.parentId !== '0') {
            //   this.$refs.shareModal.handleShow(
            //     row,
            //     '子账号不可分享文件，请使用主账号分享',
            //     2
            //   )
            //   return
            // }
            try {
                let shareRes = await getShareInfo({
                    serialNo: row.serialNo,
                })
                if (shareRes.shareInfoExist === 2) {
                    this.$refs.shareInfo.handleShow(row, shareRes)
                } else if (shareRes.shareInfoExist === 1) {
                    this.$refs.shareAddress.handleShow(shareRes)
                }
            } catch (error) {
                //该子账号无分享权限，请联系主账号分配权限。
                if(error.data.code==20000){
                    if(this.$i18n.locale=='en'){
                        this.$refs.shareModal.handleShow(row,'This sub-account has no permission to share. Please contact the main account to assign permissions.',2)
                    }else{
                        this.$refs.shareModal.handleShow(row,error.data.message,2)
                    }
                    return
                }
            }
        },
        //  停止 开始分享
        shareMethods() {
            this.getOpusList(filterParams(this.searchParam));
        },
        // 显示备注
        showApplyRemark(index, data) {
            this.$delete(this.tableData[index],'modified');
            this.tableData[index].modified = true;
            this.$set(this.tableData, index, this.tableData[index]);
        },
        // 编辑备注
        async editApplyRemark(index, data) {
            this.$delete(this.tableData[index],'modified');
            this.tableData[index].modified = false;
            this.$set(this.tableData, index, this.tableData[index]);
            const { serialNo, applyRemark } = data;
            let res = await updateOpus({ serialNo, applyRemark });
            if (res.code === 200) {
                this.$message.success(this.$t('protectFile.updateSuccess'));
            }
        },
        //账单表格头部样式
        headerStyle({ columnIndex }){
            if([2].includes(columnIndex)){
                return {background:'rgb(175 172 172)',color:'#fff',textAlign:'center'}
            }else{
                return {background:'rgb(175 172 172)',color:'#fff'}
            }
        },
        goRecharge(){
            this.downNoEnoughDiaBox=false;
            this.updateBalanceDiaBox=true;
            window.open(process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType='+(this.$getlocalStorage('queryData')?.partnerType==1? 1:0));
        },
        async updateBalance(){
            try {
                let balance = await findBalance(0)
                this.$store.commit('balance/setBalance', balance)
            } catch (error) {
                this.$message.error(this.$t('protectFile.selectBalance'))
            }
        },
        renderHeader(h, data) {
            if(this.$i18n.locale==='zh'){
                return h("span", [data.column.label]);
            }else{
                return h("span", [
                    h(
                        "el-tooltip",
                        {
                            attrs: {
                            class: "item",
                            effect: "dark",
                            content: data.column.label,
                            placement: "top",
                            },
                        },
                        [h("span", data.column.label)]
                    ),
                ]);
            }
        },
    },
};
</script>
<style src="../../assets/css/protectFile.less" lang="less" scoped></style>

<style scoped lang="less">
.selectTime{
    width: 295px;
}
.downDiaBox{
    /deep/.el-dialog{ 
        height: auto;
    }
    /deep/ .el-dialog__body{
        word-break: normal;
    }
    .dialog_cnt{
        text-align: center;
        .icon {
            display: flex;
            justify-content: center;
            color: #F3B752;
            text-align: center;
            font-size: 50px;
            margin-bottom: 19px;
        }
        h3{
            font-size: 16px;
        }
        .alignLeft{
            text-align: left;
        }
        .red{
            color: red;
        }
        .blue{
            color: #6288fb;
        }
    }
    .dialog_cnt_en{
        text-align: left;
    }
    
}
.rechargeDiaBox{
    /deep/.el-dialog{ 
        height: 200px;
    }
}
/deep/.search .el-table th.el-table__cell>.cell {
    word-break: keep-all !important;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
