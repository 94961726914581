import { render, staticRenderFns } from "./ProtectFile.vue?vue&type=template&id=48c65766&scoped=true&"
import script from "./ProtectFile.vue?vue&type=script&lang=js&"
export * from "./ProtectFile.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/protectFile.less?vue&type=style&index=0&id=48c65766&lang=less&scoped=true&"
import style1 from "./ProtectFile.vue?vue&type=style&index=1&id=48c65766&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c65766",
  null
  
)

export default component.exports