<template>
    <div>
        <el-table
            :empty-text="$t('table.noData')"
            fit
            highlight-current-row
            :ref="tableName"
            :data="tableList"
            :border="border"
            :stripe="stripe"
            :row-key="getRowKey"
            :reserve-selection="true"
            :span-method="spanMethod"
            :header-cell-style="headerStyle"
            :show-header="showHeader"
            @selection-change="selectionChange"
            @row-click="rowClick"
            :max-height="maxHeight"
        >
            <!-- 选择框 -->
            <el-table-column
                v-if="select"
                type="selection"
                :reserve-selection="true"
                width="55"
                align="center"
            />
            <el-table-column
                v-if="isIndex"
                type="index"
                :label="indexLabel"
                align="center"
                width="50"
            ></el-table-column>
            <template v-for="(itm, idx) in header">
                <!-- 特殊处理列 -->
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="55"
                    align="center"
                    v-if="itm.checkBox"
                    :key="idx"
                    :selectable='itm.formatter'
                >
                </el-table-column>
                <el-table-column
                    v-else-if="itm.render"
                    :key="idx"
                    :prop="itm.prop ? itm.prop : null"
                    :index="itm.index ? itm.index : null"
                    :label="itm.label ? itm.label : null"
                    :width="itm.width ? itm.width : null"
                    :sortable="itm.sortable ? itm.sortable : false"
                    :align="itm.align ? itm.align : 'center'"
                    :fixed="itm.fixed ? itm.fixed : null"
                    :min-width="itm.minWidth ? itm.minWidth : 50"
                >
                    <template slot-scope="scope">
                        <ex-slot
                            :cancel="itm.cancel"
                            :render="itm.render"
                            :row="scope.row"
                            :index="scope.$index"
                            :column="itm"
                        />
                    </template>
                </el-table-column>
                <slot v-else-if="itm.slot" :name="itm.slot"></slot>
                <!-- 正常列 -->
                <!-- <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    :content="itm.prop ? itm.prop : null"
                    placement="bottom"
                    :key="idx"
                > -->
                    <el-table-column
                        v-else
                        :key="idx"
                        :prop="itm.prop ? itm.prop : null"
                        :label="itm.label ? itm.label : null"
                        :width="itm.width ? itm.width : null"
                        :sortable="itm.sortable ? itm.sortable : false"
                        :align="itm.align ? itm.align : 'left'"
                        :fixed="itm.fixed ? itm.fixed : null"
                        :formatter="itm.formatter"
                        :min-width="itm.minWidth ? itm.minWidth : 50"
                        :show-overflow-tooltip="true"
                        :render-header="itm.renderHeader? itm.renderHeader : null"
                    >
                    </el-table-column>
                <!-- </el-tooltip> -->
            </template>
        </el-table>
        <div class="page-block" style="text-align:right" v-if="hasPagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="activePage"
                :page-sizes="[10, 20, 30]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                background
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
var exSlot = {
    functional: true,
    props: {
        row: Object,
        render: Function,
        cancel:Function,
        index: Number,
        column: {
            type: Object,
            default: null,
        },
    },
    render: (h, context) => {
        const params = {
            row: context.props.row,
            index: context.props.index,
        };
        if (context.props.column) params.column = context.props.column;
        return context.props.render(h, params);
    },
};
export default {
    name: "ExTable",
    props: {
        maxHeight:{type: Number, default: 10000},
        tableList: {type: Array,default: () => []},
        stripe:{ type: Boolean, default: false },
        tableName:{ type: String, default: "table" },
        showHeader:{type: Boolean, default:true},
        header: {type: Array, default: () => []},
        headerStyle:{
            type: Function,
            default: ({ columnIndex })=>{
                if([2].includes(columnIndex)){
                    return {background:'#FAFAFA',color:'#333',textAlign:'center'}
                }else{
                    return {background:'#FAFAFA',color:'#333'}
                }
            }
        },
        border:{type: Boolean, default:true},
        select: {type: Boolean, default: () => false,},
        isIndex: {type: Boolean, default: () => true},
        total: {type: Number, default: 10},
        indexLabel: { type: String, default: "序号" },
        tableId: { type: Number, default: 1 },
        activeIndex: { type: Number, default: 1 },
        spanMethod:{type: Function, default: null},
        hasPagination:{type: Boolean, default: true},
        pageSizeNum: { type: Number, default: 10 },
        renderHeader:{type: Function, default: null},
    },
    data() {
        return {
            currentPage:1,
            pageSize: this.pageSizeNum,
            activePage:this.activeIndex,
        };
    },
    components: {
        exSlot,
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {});
    },
    destroyed() {},
    watch: {
        tableList() {
            this.$nextTick(() => {});
        },
        activeIndex(){
            this.activePage=this.activeIndex;
        },
        pageSizeNum(){
            this.$nextTick(() => {
                this.pageSize=this.pageSizeNum;
            });
        }
    },
    methods: {
        getRowKey(row){
          return row.id
        },
        handleSizeChange(val) {
            this.currentPage=1;
            this.$emit("clickSize", val, this.tableId);
        },
        handleCurrentChange(val) {
            this.$emit("clickPage", val, this.tableId);
        },
        selectionChange(selection) {
            this.$emit("selection-change", selection);
        },
        rowClick(row, column, event) {
            this.$emit("row-click", row, column, event);
        },
    },
};
</script>

<style scoped>
.page-block{
    text-align: right;
    margin-top:40px;
}
</style>
