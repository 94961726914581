<template>
    <div class="myslide">
        <div :id="id"></div>
    </div>
</template>
<script type="text/javascript"></script>
<script>
import { mapGetters } from "vuex";

export default {
    props: {
        id: {
            type: String,
            default: "captcha",
        },
    },
    components: {},
    data() {
        return {
            windowNc: {},
            verify: false,
            nvc:'',
        };
    },
    computed: {
        ...mapGetters([]),
    },
    watch: {},
    methods: {
        set_ALY_config() {
            let that = this;
            let myLang = {
                cn: {
                    FAIL: "验证失败，请手动刷新页面重试",
                },
            };
            //*****************有痕验证***********************
            let ncOption = {
                // 应用类型标识。它和使用场景标识（scene字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                appkey: "FFFF0N000000000099FD",
                //使用场景标识。它和应用类型标识（appkey字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
                scene: "nc_login",
                renderTo: that.id,
                language:that.$getlocalStorage("language")=='en'?'en':'cn',
                //upLang: myLang,
                // 二次验证获取人机信息串，跟随业务请求一起上传至业务服务器，由业务服务器进行验签。
                success: function (data) {
                    //window.console && console.log(data);
                    that.nvc=data.sessionId +"|" +data.sig +"|" +data.token +"|nc_login";
                    that.verify = true;
                    that.alicode({ verify: that.verify });
                },
                // 前端二次验证失败时触发该回调参数
                fail: function (failCode) {
                    window.console && console.log(failCode);
                    that.verify = false;
                },
                // 前端二次验证加载异常时触发该回调参数。
                error: function (errorCode) {
                    window.console && console.log(errorCode);
                    that.verify = false;
                },
            };
            // 实例化nc 对有痕验证进行初始化操作
            AWSC.use("nc", function (state, module) {
                that.module = module;
                // 初始化 调用module.init进行初始化
                window.nc = module.init(ncOption);
                that.windowNc=window.nc;
            });
        },
        alicode(data) {
            this.$emit("verification", { ...data, nvc: this.nvc });
        },
        resetNvc() {
            let that = this;
            that.windowNc.reset();
            that.verify = false;
        },
    },
    created() {},
    mounted() {
        this.set_ALY_config();
    },
};
</script>

<style lang="less" scoped>
.myslide {
    /* 阿里无痕验证 */
    /deep/ .nc-container {
        margin-bottom: 15px;
    }

    /deep/ .nc-container .nc_scale {
        background: #f0f0f0 !important;
    }

    /deep/ .nc-container .nc_scale div.nc_bg {
        background: #a8d5ff !important;
    }

    /deep/ .nc-container .nc_scale .scale_text2 {
        color: #fff !important;
    }

    /deep/ .nc-container .nc_scale span {
        border: none !important;
    }

    /deep/ .nc-container .errloading {
        border: #faf1d5 1px solid !important;
        color: #ef9f06 !important;
    }

    /* 阿里无痕验证 */

    /deep/ .nc-container {
        position: static !important;
    }

    /deep/ .nc-container .nc_wrapper {
        width: 100% !important;
        height:32px !important;
    }

    /deep/ .nc-container .errloading {
        width: 100% !important;
        box-sizing: border-box;
    }

    /* 外层父元素 */

    /deep/ .nc-container .nc_scale {
        height:32px !important;
        line-height:32px !important;
        border-radius: 4px;
        overflow: hidden;
    }

    /* 上层条 */

    /deep/ .nc-container .nc_scale span.nc-lang-cnt {
        box-sizing: content-box;
        height:32px !important;
        line-height:32px !important;
        border: none !important;
        border-radius: 4px;
        font-size: 14px;
    }

    /* 滑块 */

    /deep/ .nc-container .nc_scale .btn_slide {
        border: none !important;
        height:32px !important;
        line-height:32px !important;
        background: #4460f1 !important;
        color: #f5f5f5 !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width:32px !important;
    }

    /deep/ .nc-container .nc_scale .btn_ok {
        height:32px !important;
        line-height:32px !important;
        font-size: 30px !important;
        width:32px !important;
        background: #f0f0f0;
    }

    /* 下层条 */

    /deep/ .nc-container .nc_scale div.nc_bg {
        /* border-radius: 4px; */
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}
</style>
